var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tariff-comparison"},[_c('div',{staticClass:"columns has-text-centered is-flex-wrap-wrap tariff-header"},[_vm._l((_vm.field.properties.basic.tariffs),function(tariff,tIndex){return [(tariff.isVisible === undefined ? true : tariff.isVisible)?_c('div',{key:'tariff-header' + tIndex,staticClass:"column",class:[
          _vm.field.properties.basic.highlightedTariffText && tariff.isHighlighted ? 'is-tariff-highlighted' : '',
          ("is-" + (12/_vm.tariffsLength))
        ]},[_vm._v(" "+_vm._s(_vm.field.properties.basic.highlightedTariffText && tariff.isHighlighted ? _vm.field.properties.basic.highlightedTariffText : '')+" ")]):_vm._e()]})],2),_c('div',{staticClass:"columns has-text-centered is-flex-wrap-wrap tariff-title"},[_vm._l((_vm.field.properties.basic.tariffs),function(tariff,tIndex){return [(tariff.isVisible === undefined ? true : tariff.isVisible)?_c('h1',{key:'title' + tIndex,staticClass:"title column my-0 px-2",class:[
          tariff.isHighlighted ? 'is-tariff-highlighted' : '',
          ("is-" + (12/_vm.tariffsLength))
        ]},[_vm._v(" "+_vm._s(tariff.title)+" ")]):_vm._e()]})],2),_c('div',{staticClass:"columns has-text-centered is-flex-wrap-wrap tariff-price"},[_vm._l((_vm.field.properties.basic.tariffs),function(tariff,tIndex){return [(tariff.isVisible === undefined ? true : tariff.isVisible)?_c('h2',{key:'price' + tIndex,staticClass:"subtitle column my-0 px-2",class:[
          tariff.isHighlighted ? 'is-tariff-highlighted' : '',
          ("is-" + (12/_vm.tariffsLength))
        ]},[_vm._v(" "+_vm._s(tariff.price)+" ")]):_vm._e()]})],2),_c('div',{staticClass:"columns has-text-centered is-flex-wrap-wrap"},[_vm._l((_vm.field.properties.basic.tariffs),function(tariff,tIndex){return [(tariff.isVisible === undefined ? true : tariff.isVisible)?_c('div',{key:'description' + tIndex,staticClass:"column mt-0 mb-2 px-2 tariff-description",class:[
          tariff.isHighlighted ? 'is-tariff-highlighted' : '',
          ("is-" + (12/_vm.tariffsLength))
        ],domProps:{"innerHTML":_vm._s(tariff.description)}}):_vm._e()]})],2),_c('div',{staticClass:"columns has-text-centered is-flex-wrap-wrap"},[_vm._l((_vm.field.properties.basic.tariffs),function(tariff,tIndex){return [(tariff.isVisible === undefined ? true : tariff.isVisible)?_c('div',{key:'select-tariff' + tIndex,staticClass:"column mt-0 mb-2 px-4",class:'is-' + (12/_vm.tariffsLength)},[(tariff.isButtonVisible === undefined ? true : tariff.isButtonVisible)?_c('b-button',{attrs:{"type":"is-primary","expanded":""},on:{"click":function($event){return _vm.$emit('update', {
            value: tariff
          })}}},[_vm._v(" "+_vm._s(_vm.field.properties.basic.tariffSelectionButton.text || 'Select')+" ")]):_vm._e()],1):_vm._e()]})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }