<template>
  <div
    class="tariff-comparison"
  >
    <div class="columns has-text-centered is-flex-wrap-wrap tariff-header">
      <template
        v-for="(tariff, tIndex) in field.properties.basic.tariffs"
      >
        <div
          v-if="tariff.isVisible === undefined ? true : tariff.isVisible"
          :key="'tariff-header' + tIndex"
          class="column"
          :class="[
            field.properties.basic.highlightedTariffText && tariff.isHighlighted ? 'is-tariff-highlighted' : '',
            `is-${12/tariffsLength}`
          ]"
        >
          {{ field.properties.basic.highlightedTariffText && tariff.isHighlighted ? field.properties.basic.highlightedTariffText : '' }}
        </div>
      </template>
    </div>
    <div class="columns has-text-centered is-flex-wrap-wrap tariff-title">
      <template
        v-for="(tariff, tIndex) in field.properties.basic.tariffs"
      >
        <h1
          v-if="tariff.isVisible === undefined ? true : tariff.isVisible"
          :key="'title' + tIndex"
          class="title column my-0 px-2"
          :class="[
            tariff.isHighlighted ? 'is-tariff-highlighted' : '',
            `is-${12/tariffsLength}`
          ]"
        >
          {{ tariff.title }}
        </h1>
      </template>
    </div>
    <div class="columns has-text-centered is-flex-wrap-wrap tariff-price">
      <template
        v-for="(tariff, tIndex) in field.properties.basic.tariffs"
      >
        <h2
          v-if="tariff.isVisible === undefined ? true : tariff.isVisible"
          :key="'price' + tIndex"
          class="subtitle column my-0 px-2"
          :class="[
            tariff.isHighlighted ? 'is-tariff-highlighted' : '',
            `is-${12/tariffsLength}`
          ]"
        >
          {{ tariff.price }}
        </h2>
      </template>
    </div>
    <div class="columns has-text-centered is-flex-wrap-wrap">
      <template
        v-for="(tariff, tIndex) in field.properties.basic.tariffs"
      >
        <div
          v-if="tariff.isVisible === undefined ? true : tariff.isVisible"
          :key="'description' + tIndex"
          class="column mt-0 mb-2 px-2 tariff-description"
          :class="[
            tariff.isHighlighted ? 'is-tariff-highlighted' : '',
            `is-${12/tariffsLength}`
          ]"
          v-html="tariff.description"
        />
      </template>
    </div>                                
    <div class="columns has-text-centered is-flex-wrap-wrap">
      <template
        v-for="(tariff, tIndex) in field.properties.basic.tariffs"
      >
        <div
          v-if="tariff.isVisible === undefined ? true : tariff.isVisible"
          :key="'select-tariff' + tIndex"
          class="column mt-0 mb-2 px-4"
          :class="'is-' + (12/tariffsLength)"
        >
          <b-button
            v-if="tariff.isButtonVisible === undefined ? true : tariff.isButtonVisible"
            type="is-primary"
            expanded
            @click="$emit('update', {
              value: tariff
            })"
          >
            {{ field.properties.basic.tariffSelectionButton.text || 'Select' }}
          </b-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script >
import { computed } from '@vue/composition-api';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const tariffsLength = computed(() => (props.field?.properties?.basic?.tariffs?.filter(tariff => tariff.isVisible === undefined ? true : tariff.isVisible) || ['']).length);
  return {
    tariffsLength
  };
};
export default __sfc_main;
</script>
